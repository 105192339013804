<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">问卷列表</div>
      </el-col>
      <el-col :span="24">
         <el-table :data="project.patrolSurveyList" :default-sort = "{prop: 'account', order: 'desc'}" style="width: 100%">
          <el-table-column prop="name" label="问卷名称" sortable></el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="getDetail(scope.row)"
                type="text"
                size="mini"
              > 查看统计
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      pageSize: 20,
      recordList: [],
      project: {
        guid: "",
        name: "",
        patrolSurveyList: []
      }
    };
  },
  components: {
    // cwxSearch,
  },
  props:[],
  watch:{
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getProjectDetail(self.$route.query.guid || null);
  },

  methods: {
    getProjectDetail(guid) {
      let self = this;
      self.$axios
        .get("/project/get/" + guid, {
          headers: { token: self.userInfo.token },
        }).then(function(response) {
          let _keys = Object.keys(self.project);
          console.log("_keys = ", _keys)
          console.log("response.data.data = ", response.data.data);
          for (let i = 0; i < _keys.length; i++) {
            console.log("i = ", i);
            console.log("_keys[i] = ", _keys[i]);
            console.log("self.project[_keys[i]] = ", self.project[_keys[i]]);
            console.log("response.data.data[_keys[i]] = ", response.data.data[_keys[i]]);
            self.project[_keys[i]] = response.data.data[_keys[i]];
          }
        }).catch(function (error) {
          self.$message({
            message: "问卷列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "ReportpatrolSurveyDetail", query: { projectGuid: self.project.guid, surveyGuid: row.guid } });
    },
    createProject() {
      let self = this;
      self.$router.push({ name: "ProjectDetail", query: { guid: null } });
    },
    handleSizeChange(val) {
      this.pageSize = val * 1;
      this.getUserPageNum()
    },
    handleCurrentChange(val) {
      this.getUserPageNum(val)
    },
    getUserPageNum(val) {
      this.getProjectDetail(val);
    },
  }
};
</script>
